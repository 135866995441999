import { Market } from '@/network/graphql.g'
import { StoreContext } from '@/providers/storeProvider'
import { validFor } from '@/theme/validFor'
import { useRouter } from 'next/router'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Box, Flex, Grid } from '@/components/atoms/Grid'
import { StyledA } from '@/components/atoms/link'
import ResponsiveImage from '@/components/atoms/responsiveImage'
import styled, { css, useTheme } from 'styled-components'
import { Body, Tiny, Tiny2 } from '@/components/atoms/typography'
import { transparentize } from 'polished'
import { ArrowDownIconDefault } from '../atoms/icons/ArrowDownIconDefault'
import { applyImagePolicy } from '@/utils/imageExtension'

export enum MarketDropdownMode {
  Footer,
  SideMenu
}

type MarketDropdownProps = {
  markets: Market[]
  mode?: MarketDropdownMode
}

const MarketDropdown: FC<MarketDropdownProps> = ({
  markets,
  mode = MarketDropdownMode.Footer
}) => {
  const { subdomainName, isFrogies } = useContext(StoreContext)
  const [url, setUrl] = useState<URL>()
  const theme = useTheme()
  const router = useRouter()

  useEffect(() => {
    setUrl(new URL(window.location.href))
  }, [router])

  const value = markets?.find(({ code }) => code === subdomainName)

  const getHref = useCallback(
    (code: string) => {
      if (url) {
        const [, ...rest] = url.host.split('.')
        if (rest.length > 0) {
          if (isFrogies) {
            return `${url.protocol}//www.frogies.${
              code === 'cs' ? 'cz' : code
            }${url.port ? ':' + url.port : ''}${url.pathname}${url.search}${
              url.hash
            }`
          }
          return `${url.protocol}//${code}.${rest.join('.')}${url.pathname}${
            url.search
          }${url.hash}`
        }
      }
      return ''
    },
    [url]
  )

  return (
    <Wrapper
      p={mode === MarketDropdownMode.SideMenu ? 0 : 2}
      pl={{ desktop: 2, tablet: 0, mobile: 0 }}
      ml={{ desktop: 2, tablet: 0, mobile: 0 }}
    >
      <StyledFlex
        mode={mode}
        cursorOnHover={true}
        zIndex={8}
        alignItems="center"
      >
        <ResponsiveImage
          imageUrl={applyImagePolicy(`/flags/${value?.image}`)}
          layout="fixed"
          width={16}
          height={11}
        />
        {mode === MarketDropdownMode.Footer ? (
          <BottomText mode={mode} ml={2}>
            {value?.name}
          </BottomText>
        ) : (
          <StyledTiny2 mode={mode} ml={2}>
            {value?.name}
          </StyledTiny2>
        )}
        <RotableArrow
          color={
            mode === MarketDropdownMode.Footer
              ? theme.colors.background
              : theme.colors.onBackground
          }
        />
      </StyledFlex>
      <DropdownContainer mode={mode}>
        <StyledGrid
          gridTemplateColumns={
            mode === MarketDropdownMode.Footer ? '1fr 1fr' : '1fr'
          }
          gridColumnGap={16}
        >
          {markets?.map(({ name, image, code }) => (
            <StyledA href={getHref(code)} key={code}>
              <Flex key={code} alignItems="center">
                <ResponsiveImage
                  imageUrl={applyImagePolicy(`/flags/${image}`)}
                  layout="fixed"
                  width={16}
                  height={11}
                />
                <StyledTiny m={0} ml={2}>
                  {name}
                </StyledTiny>
              </Flex>
            </StyledA>
          ))}
        </StyledGrid>
      </DropdownContainer>
    </Wrapper>
  )
}

export default MarketDropdown

const StyledGrid = styled(Grid)`
  padding: 10px;
`

const StyledTiny = styled(Tiny)`
  opacity: 0.6;
  font-weight: normal;
`

const RotableArrow = styled(ArrowDownIconDefault)`
  margin-left: 5px;
`

const DropdownContainer = styled(Box)`
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  width: ${({ mode }) =>
    mode === MarketDropdownMode.SideMenu ? 'auto' : 'max-content'};
  max-width: 500px;
  min-height: 50px;
  max-height: ${({ mode }) =>
    mode === MarketDropdownMode.SideMenu ? '200px' : '500px'};
  overflow-y: auto;
  overflow-x: visible;
  background-color: ${(props) => props.theme.colors.background};
  border: solid 1px ${(props) => props.theme.colors.sectionSeparator};
  border-radius: ${(props) => props.theme.borderRadius}px;
  ${validFor.mobile`  
    left: 0;
  `}
`

const Wrapper = styled(Flex)`
  position: relative;
  ${RotableArrow} {
    transform: rotate(180deg);
    transition: 0.2s ease-out;
    transform: rotate(0deg);
  }
  &:hover {
    z-index: 9;
    ${RotableArrow} {
      transform: rotate(0deg);
      transition: 0.2s ease-out;
      transform: rotate(180deg);
    }

    ${DropdownContainer} {
      display: block;
    }
  }
  ${validFor.tablet`
    width: 100%;
  `}
`

const WidthBasedOnMode = css`
  ${({ mode }) => (mode === MarketDropdownMode.SideMenu ? 'width: 100%;' : '')}
`

const BottomText = styled(Body)`
  color: ${({ theme }) =>
    transparentize(1 - theme.footerTextTransparency, theme.colors.background)};
  margin-block-start: 0;
  margin-block-end: 0;
  ${WidthBasedOnMode}
`

const StyledTiny2 = styled(Tiny2)`
  ${WidthBasedOnMode}
`

const StyledFlex = styled(Flex)`
  ${({ theme, mode }) =>
    mode === MarketDropdownMode.SideMenu
      ? `border: 1px solid ${theme.colors.onBackground}; padding: 0 10px;`
      : ''}
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  ${WidthBasedOnMode}
`
