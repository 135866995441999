import { FC } from 'react'
import styled from 'styled-components'
import { Flex } from '@/components/atoms/Grid'
import Link from '@/components/atoms/link'
import useTranslation from 'next-translate/useTranslation'
import { validFor } from '@/theme/validFor'
import { ItemsForFullTextProduct } from '@/network/graphql.g'
import { ProductSearchCategories } from '@/components/molecules/productSearch/productSearchData'
import { v4 as uuidv4 } from 'uuid'

type CategoriesListingItemsWrapperProps = {
  hideOnClick?: () => void
  categories: ItemsForFullTextProduct[]
}

const CategoriesListingItemsWrapper: FC<CategoriesListingItemsWrapperProps> = ({
  hideOnClick,
  categories
}) => {
  const { t } = useTranslation('common')
  const productSearchCategories = ProductSearchCategories()

  return (
    <CategoriesListingItems onClick={hideOnClick}>
      <StyledCategoryTitle>
        {t('Luigi.Types.categoryTitle')}
      </StyledCategoryTitle>
      {categories.length > 0
        ? categories.map((category) => (
            <StyledCategory
              key={uuidv4()}
              href={category.url}
              data-cy="categoriesListingItems"
            >
              {category.title}
            </StyledCategory>
          ))
        : productSearchCategories.map((dummyCategory) => (
            <StyledCategory href={dummyCategory.url} key={dummyCategory.id}>
              {dummyCategory.title}
            </StyledCategory>
          ))}
    </CategoriesListingItems>
  )
}

export default CategoriesListingItemsWrapper

const CategoriesListingItems = styled(Flex)`
  flex-direction: column;
  padding-top: 12px;
  width: 90%;
  ${validFor.tablet`
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 0;
    padding-left: 20px;
  `};
  ${validFor.mobile`
    padding-top: 0;
  `};
`

const StyledCategoryTitle = styled(Flex)`
  font-size: 18px;
  padding: 10px;
  ${validFor.tablet`
    width: 100%;
    padding: 13px 0px;
  `};
  ${validFor.mobile`
    padding-bottom:6px;;
  `};
`

const StyledCategory = styled(Link)`
  padding: 2px 13px;
  margin: 2px;
  font-size: 12px;
  ${validFor.tablet`
    width: 30%;
    height : 20px;
    padding: 0;
  `};
  &::before {
    content: '- ';
    position: relative;
    left: 0;
    padding-right: 5px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.luigiHoverColor};
  }
`
