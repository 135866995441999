import { FC } from 'react'
import { SvgProps } from './svgProps'

const AccountIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 17}
    height={height || 17}
    viewBox="0 0 17 17"
  >
    <defs>
      <filter id="tkb6k6y05a" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-227 -56)">
        <g>
          <path d="M0 0H17V17H0z" transform="translate(227 56)" />
          <g>
            <path
              fill={color || '#595959'}
              d="M5.1.567h4.533l.567 1.7-.883-.442c-.15-.075-.329-.07-.474.015-.492.284-.984.427-1.476.427s-.984-.143-1.476-.427c-.146-.085-.324-.09-.474-.015l-.884.442.567-1.7z"
              transform="translate(227 56) translate(1.133 1.133)"
            />
            <path
              stroke={color || '#595959'}
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M7.367 0c1.644 0 2.976 1.333 2.976 2.977 0 .131-.008.263-.026.393l-.384 2.883C9.76 7.54 8.664 8.5 7.367 8.5c-1.298 0-2.395-.96-2.567-2.247L4.416 3.37C4.199 1.74 5.344.243 6.973.026 7.103.01 7.235 0 7.367 0zM3.967 9.633l-2.082.52c-.796.2-1.39.864-1.498 1.677l-.104.778c-.137 1.028.585 1.972 1.613 2.109.082.01.165.016.248.016H12.59c1.036 0 1.876-.84 1.876-1.877 0-.083-.005-.165-.016-.248l-.104-.778c-.108-.813-.702-1.477-1.497-1.676l-2.082-.52h0"
              transform="translate(227 56) translate(1.133 1.133)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AccountIcon
