import {
  forwardRef,
  Fragment,
  useContext,
  useImperativeHandle,
  useState
} from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import MobileMenuBox, { ArrowState } from '@/components/molecules/mobileMenuBox'
import Link from '@/components/atoms/link'
import ListItem from '@/components/molecules/listButton'
import { toggleExpandedIndex } from '@/core/utils'
import { NavigationItem, PremiumManufacturer } from '@/network/graphql.g'
import MenuBadge from '@/components/atoms/menuBadge'
import styled from 'styled-components'
import { ListItemSeparator } from '@/components/atoms/separator'
import useTranslation from 'next-translate/useTranslation'
import { Translate } from 'next-translate'
import { HomeBanner } from '@/network/graphql.g'
import BannersLayout, { BannersType } from '../bannersLayout'
import { StoreContext } from '@/providers/storeProvider'
import PremiumBrands from '@/components/molecules/premiumBrands'
import { useMainNavigationDispatch } from '@/providers/mainNavigationMenuProvider'
import { CategoryIconsList } from '@/components/atoms/svg/categoryIcons/categoryIconsList'

enum MainCategoryId {
  Accessories = '112',
  Sports = '719'
}

type MobileSubcategoriesMenuProps = {
  categoryId: string
  navigationItems: NavigationItem[]
  banners: HomeBanner[]
  premiumManufacturers: PremiumManufacturer[]
}

export type MobileSubcategoriesMenuActions = {
  setSubcategories: (
    categoryId: string,
    subcategories: NavigationItem[],
    banners: HomeBanner[],
    premiumManufacturers: PremiumManufacturer[]
  ) => void
}

const MobileSubcategoriesMenu = forwardRef<
  MobileSubcategoriesMenuActions,
  MobileSubcategoriesMenuProps
>((props, ref) => {
  const { t } = useTranslation('common')
  const [subcategoriesInfo, setSubcategoriesInfo] = useState<{
    categoryId: string
    subcategories: NavigationItem[]
    expandedIndices: number[]
    banners: HomeBanner[]
    premiumManufacturers: PremiumManufacturer[]
  }>({
    categoryId: props.categoryId,
    subcategories: props.navigationItems,
    expandedIndices: [],
    banners: props.banners,
    premiumManufacturers: props.premiumManufacturers
  })
  useImperativeHandle(ref, () => ({
    setSubcategories: (
      categoryId,
      subcategories,
      banners,
      premiumManufacturers
    ) => {
      setSubcategoriesInfo({
        categoryId,
        subcategories,
        banners,
        expandedIndices: [],
        premiumManufacturers
      })
    }
  }))
  const { categoryId, subcategories, expandedIndices, banners } =
    subcategoriesInfo
  const { isFrogies } = useContext(StoreContext)
  const premiumOffset = -1
  const premiumExpandedState = expandedIndices.includes(premiumOffset)
    ? ArrowState.opened
    : ArrowState.closed
  const premiumIsOpened = premiumExpandedState == ArrowState.opened
  return (
    <>
      <Box ref={ref} data-cy="mobileSubcategoriesMenu" py={15}>
        {subcategories &&
          subcategories.map((subcategory, offset) =>
            renderItem(
              categoryId,
              subcategory,
              expandedIndices.includes(offset)
                ? ArrowState.opened
                : ArrowState.closed,
              () =>
                setSubcategoriesInfo({
                  ...subcategoriesInfo,
                  expandedIndices: toggleExpandedIndex(offset, expandedIndices)
                }),
              t
            )
          )}
        {!isFrogies && subcategoriesInfo.premiumManufacturers?.length > 0 && (
          <Box data-cy="mobileSubcategory">
            <MobileMenuBox
              mb={premiumIsOpened ? 0 : 10}
              arrowState={premiumExpandedState}
              title={t('Menu.premium')}
              onClick={() =>
                setSubcategoriesInfo({
                  ...subcategoriesInfo,
                  expandedIndices: toggleExpandedIndex(
                    premiumOffset,
                    expandedIndices
                  )
                })
              }
            />
            {premiumIsOpened && (
              <PremiumBrands
                pl="18px"
                py={3}
                premiumManufacturers={subcategoriesInfo.premiumManufacturers}
              />
            )}
          </Box>
        )}
        {banners && (
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            position="relative"
          >
            <Flex flexDirection="column" pt={3} minWidth="100%">
              <BannersLayout
                banners={banners?.slice(0, 2)}
                bannerDst={BannersType.Dropdown}
              />
            </Flex>
          </Flex>
        )}
      </Box>
    </>
  )
})

const renderItem = (
  mainCategoryId: string,
  subCategory: NavigationItem,
  expandedState: ArrowState,
  onClick: () => void,
  t: Translate
): JSX.Element => {
  const isOpened = expandedState == ArrowState.opened
  const dontShowIconsOnCategory = [
    MainCategoryId.Accessories,
    MainCategoryId.Sports
  ].includes(mainCategoryId as MainCategoryId)
  const { slugPath } = useMainNavigationDispatch()

  const subcategoryItems = subCategory.url
    ? [
        ...[
          {
            categoryId: '-1',
            name: t('Category.showAll'),
            url: `${subCategory.url ?? '#'}`
          } as NavigationItem
        ],
        ...subCategory.children
      ]
    : subCategory.children

  return (
    <Box key={subCategory.url} data-cy="mobileSubcategory">
      <MobileMenuBox
        mb={isOpened ? 0 : 10}
        arrowState={expandedState}
        title={subCategory.name}
        onClick={onClick}
        highlight={subCategory.isHighlight}
        badge={subCategory.mobileBadge || subCategory.desktopBadge}
        data-cy={subCategory.id}
        isActive={slugPath.find((path) => path.id === subCategory.categoryId)}
      />

      {isOpened &&
        (subCategory.children.length > 0 ? (
          subcategoryItems?.map(
            (
              { name, categoryId, url, isHighlight, mobileBadge, desktopBadge },
              offset
            ) => (
              <Fragment key={url}>
                <StyledLink
                  href={url}
                  data-cy={categoryId}
                  showIcons={CategoryIconsList?.[categoryId]}
                  dontShowIconsOnCategory={dontShowIconsOnCategory}
                >
                  {!dontShowIconsOnCategory &&
                    CategoryIconsList?.[categoryId] &&
                    CategoryIconsList?.[categoryId]}
                  <ListItem
                    id={categoryId}
                    title={name}
                    highlight={isHighlight}
                    separator={false}
                    isActive={slugPath.find((path) => path.id === categoryId)}
                  />
                  {(mobileBadge || desktopBadge) && (
                    <MenuBadge
                      imageUrl={mobileBadge || desktopBadge}
                      maxHeight={20}
                      ml={10}
                    />
                  )}
                </StyledLink>
                {offset !== subCategory.children.length - 1 && (
                  <ListItemSeparator />
                )}
              </Fragment>
            )
          )
        ) : (
          <Fragment>
            <StyledLink
              href={`${subCategory.url ?? '#'}`}
              data-cy="-1"
              showIcons={CategoryIconsList?.['-1']}
              dontShowIconsOnCategory={dontShowIconsOnCategory}
            >
              {!dontShowIconsOnCategory &&
                CategoryIconsList?.['-1'] &&
                CategoryIconsList?.['-1']}
              <ListItem
                id="-1"
                title={t('Category.showAll')}
                highlight={false}
                separator={false}
                isActive={slugPath.find((path) => path.id === '-1')}
              />
            </StyledLink>
          </Fragment>
        ))}
    </Box>
  )
}

export default MobileSubcategoriesMenu

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: ${({ showIcons, dontShowIconsOnCategory }) =>
    showIcons && !dontShowIconsOnCategory ? '18px' : '6px'};
`
