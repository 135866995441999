import { FC } from 'react'
import Flex from '@/components/atoms/Grid/Flex'
import { Tiny2 } from '@/components/atoms/typography'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { IconButton } from '@/components/molecules/iconButtons'
import Link from '@/components/atoms/link'
import MenuLogoutPart from '@/components/atoms/menuLogoutPart'
import { AccountIconDefault } from '../atoms/icons/AccountIconDefault'

type ListLoginButtonProps = {
  hrefLink: string
  title: string
  isSigned: boolean
}

const ListLoginButton: FC<ListLoginButtonProps> = ({
  hrefLink,
  title,
  isSigned
}) => {
  const router = useRouter()
  if (isSigned) {
    return (
      <Row>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            router.push(hrefLink)
          }}
        >
          <AccountIconDefault />
          <StyledTiny2 ml="9px">{title}</StyledTiny2>
        </ButtonWrapper>
        {isSigned && <MenuLogoutPart />}
      </Row>
    )
  } else {
    return (
      <Link href={hrefLink}>
        <IconButton title={title} IconElement={<AccountIconDefault />} />
      </Link>
    )
  }
}

export default ListLoginButton

const StyledTiny2 = styled(Tiny2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.6;
`

const Row = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: stretch;
`

const ButtonWrapper = styled(Flex)`
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`
