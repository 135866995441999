import type { FC } from 'react'
import { useRef } from 'react'
import {
  Desktop,
  MediaContextProvider,
  TabletOrMobile
} from '@/components/atoms/media'
import Toolbar from '@/components/organisms/toolbar/toolbar'
import { Box } from '@/components/atoms/Grid'
import MainSlideMenu, {
  MainSlideMenuActions
} from '@/components/organisms/mainSideSlideMenu'
import {
  HeaderNotice,
  Market,
  NavigationItem,
  PremiumManufacturer
} from '@/network/graphql.g'
import { ContentWrapper } from './container'
import styled from 'styled-components'
import { Asset, useAsset } from '@/providers/assetsProvider'
import { ContentPaddings } from './pageContentWrapper'

type HeaderProps = {
  markets: Market[]
  showHeaderPromo: boolean
  headerNotice: HeaderNotice
  navigationItems: NavigationItem[]
  otherMenuItems: NavigationItem[]
  premiumManufacturers: PremiumManufacturer[]
} & Rest

const Header: FC<HeaderProps> = ({
  markets,
  showHeaderPromo,
  headerNotice,
  navigationItems,
  otherMenuItems,
  premiumManufacturers,
  ...rest
}) => {
  const mainSlideMenuMenuRef = useRef<MainSlideMenuActions>(null)
  const { asset } = useAsset()

  return (
    <Box {...rest}>
      <MediaContextProvider>
        <Desktop>
          <ToolbarWrapper toolbarBackground={asset(Asset.toolbarBackground)}>
            <ContentWrapper
              px={{
                wide: ContentPaddings.wide,
                desktop: ContentPaddings.desktop
              }}
              suppressHydrationWarning
            >
              <Toolbar
                onDrawerClick={null}
                data-cy="headerTabletOrMobile"
                showHeaderPromo={showHeaderPromo}
              />
            </ContentWrapper>
          </ToolbarWrapper>
        </Desktop>
        <TabletOrMobile>
          <ContentWrapper
            px={{
              tablet: ContentPaddings.tablet
            }}
            suppressHydrationWarning
          >
            <Toolbar
              onDrawerClick={() => mainSlideMenuMenuRef?.current.open()}
              data-cy="headerTabletOrMobile"
              showHeaderPromo={showHeaderPromo}
              headerNotice={headerNotice}
            />
            <MainSlideMenu
              ref={mainSlideMenuMenuRef}
              navigationItems={navigationItems}
              otherMenuItems={otherMenuItems}
              markets={markets}
              premiumManufacturers={premiumManufacturers}
            />
          </ContentWrapper>
        </TabletOrMobile>
      </MediaContextProvider>
    </Box>
  )
}

export default Header

const ToolbarWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.toolbar};
  ${({ toolbarBackground, theme }) =>
    toolbarBackground &&
    theme.hasToolbarBackground &&
    `background-image: url(${toolbarBackground});`}
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 250px 397px;
`
