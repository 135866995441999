import { Routes } from '@/core/routes'
import ResponsiveImage from '@/components/atoms/responsiveImage'
import styled from 'styled-components'
import { Flex, Box } from '@/components/atoms/Grid'
import Link from '@/components/atoms/link'
import { validFor } from '@/theme/validFor'
import { FullTextProduct } from '@/network/graphql.g'
import { FC, useContext } from 'react'
import { CurrencyContext } from '@/providers/currencyProvider'
import { ProductSearchItems } from '@/components/molecules/productSearch/productSearchData'

type ProductSearchPopUpItemProps = {
  hideOnClick?: () => void
  products: FullTextProduct[]
}

const ProductSearchPopUpItem: FC<ProductSearchPopUpItemProps> = ({
  hideOnClick,
  products
}) => {
  const currency = useContext(CurrencyContext)
  const productSearchItems = ProductSearchItems()

  const productsForSearch =
    products.length > 0 ? products.slice(0, 6) : productSearchItems

  return (
    <>
      {productsForSearch.map((product) => (
        <Item
          key={product.id}
          href={`${Routes.product}/${product.id}`}
          data-cy="productSearchPopUpItem"
        >
          <Box
            ml={{ mobile: 0, tablet: '20px', desktop: 0 }}
            onClick={hideOnClick}
          >
            <ResponsiveImage
              width="64"
              height="64"
              imageUrl={product.image}
              layout="fixed"
            />
          </Box>
          <Flex
            flexDirection="column"
            width="50%"
            onClick={hideOnClick}
            pl={{ tablet: '10px', mobile: '20px' }}
          >
            <ProductName> {product.name} </ProductName>
            <PriceWrapper>
              <FirstPrice>
                {product?.secondPrice}
                {currency?.rightSymbol}
              </FirstPrice>
              <SecondPrice>
                {product?.finalPrice}
                {currency?.rightSymbol}
              </SecondPrice>
            </PriceWrapper>
          </Flex>
        </Item>
      ))}
    </>
  )
}

export default ProductSearchPopUpItem

const Item = styled(Link)`
  width: 50%;
  height: 85px;
  padding: 13px 0;
  align-items: center;
  justify-content: space-evenly;
  &:hover {
    background-color: ${({ theme }) => theme.colors.luigiHoverColor};
  }
  ${validFor.tablet`
    justify-content: flex-start;
  `};
  ${validFor.mobile`
    width: 100%;
    padding: 5px 0 5px 20px ;
    justify-content: flex-start;
  `};
`

const ProductName = styled(Box)`
  text-align: left;
  font-size: 14px;
`

const PriceWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
`

const FirstPrice = styled(Flex)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.onBackground};
  opacity: 0.5;
  font-size: 14px;
`

const SecondPrice = styled(Flex)`
  color: ${({ theme }) => theme.colors.accent};
  padding-left: 5px;
  font-size: 18px;
`
