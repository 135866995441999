import { FC } from 'react'
import SlideMenu, { MenuPosition } from '@/components/organisms/slideMenu'
import useTranslation from 'next-translate/useTranslation'
import AdditionalInfoContent from './additionalInfoContent'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import useDevice from 'hooks/useDevice'

const SlideMenuAdditionalInfo: FC = () => {
  const { t } = useTranslation('common')

  const { showAdditionalMenu, setShow, showAdditionalMenuOnHP } =
    useAdditionalInfoProvider()

  const { isMobile, isTablet } = useDevice()
  const menuWidth = isMobile ? '100%' : isTablet ? '50%' : '33%'

  return (
    <>
      {showAdditionalMenu || showAdditionalMenuOnHP ? (
        <SlideMenu
          defaultNavigationBartTitle={t('AdditionalInfo.title')}
          pages={[
            {
              page: <AdditionalInfoContent />,
              menuWidth
            }
          ]}
          onDidClose={() => setShow(false)}
          menuPosition={MenuPosition.Right}
          modal={false}
        />
      ) : null}
    </>
  )
}

export default SlideMenuAdditionalInfo
