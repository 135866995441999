import { FC } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'
import { SectionSeparator } from '@/components/atoms/separator'
import useTranslation from 'next-translate/useTranslation'
import { BigAccentButton } from '@/components/atoms/button'
import ProductSearchPopUpItem from '@/components/molecules/productSearch/productSearchPopUpItem'
import CategoriesListingItemsWrapper from '@/components/molecules/productSearch/categoriesListingItems'
import BrandsListingItemsWrapper from '@/components/molecules/productSearch/brandsListingItems'
import { validFor } from '@/theme/validFor'
import { Desktop, MediaContextProvider } from '@/components/atoms/media'
import Modal from '@/components/organisms/modal'
import { FullTextProduct, ItemsForFullTextProduct } from '@/network/graphql.g'
import { Body3, H2 } from '@/components/atoms/typography'

type ProductSearchPopUpProps = {
  handleClose: () => void
  isSticky?: boolean
  products: FullTextProduct[]
  manufacturers: ItemsForFullTextProduct[]
  categories: ItemsForFullTextProduct[]
  submitSearch: (e) => void
  setShowSearchInput?: (boolean) => void
  searchQuery?: string
  isHeaderNoticeEnabled?: boolean
  showHeaderPromo?: boolean
}

const ProductSearchPopUp: FC<ProductSearchPopUpProps> = ({
  handleClose,
  isSticky,
  products,
  manufacturers,
  categories,
  submitSearch,
  setShowSearchInput,
  searchQuery,
  showHeaderPromo,
  isHeaderNoticeEnabled
}) => {
  const { t } = useTranslation('common')
  const hideOnClick = () => {
    handleClose()
    setShowSearchInput && setShowSearchInput(false)
  }

  return (
    <MediaContextProvider>
      <ProductSearchCaret
        isSticky={isSticky}
        isHeaderNoticeEnabled={isHeaderNoticeEnabled}
        showHeaderPromo={showHeaderPromo}
      />
      <ModalWrapper
        isSticky={isSticky}
        onDidTapClose={hideOnClick}
        contentProps={{
          px: 0,
          py: 0,
          display: { desktop: 'flex' },
          position: 'absolute',
          minWidth: { mobile: '100%' }
        }}
        data-cy="productSearchPopUp"
        isHeaderNoticeEnabled={isHeaderNoticeEnabled}
        showHeaderPromo={showHeaderPromo}
      >
        <ProductListing>
          {products.length === 0 && searchQuery.length > 5 && (
            <NoResultWrap>
              <NoResultTitle>{t('Search.noResultTitle')}</NoResultTitle>
              <NoResultSubTitle>
                {t('Search.noResultSubTitle')}
              </NoResultSubTitle>
            </NoResultWrap>
          )}
          <StyledProductTitle pt={3}>
            {t('Luigi.Types.itemTitle')}
          </StyledProductTitle>
          <ProductItems>
            <ProductSearchPopUpItem
              hideOnClick={hideOnClick}
              products={products}
            />
          </ProductItems>
          {products.length !== 0 && (
            <ButtonWrapper onClick={submitSearch}>
              <StyledLuigiButton title={t('Luigi.showAllTitle')} />
            </ButtonWrapper>
          )}
        </ProductListing>
        <Desktop>
          <SectionSeparator isVertical width="1px" height="100%" />
        </Desktop>
        <CategoriesBrandsListing>
          <CategoriesListingItemsWrapper
            hideOnClick={hideOnClick}
            categories={categories}
          />
          <BrandsListingItemsWrapper
            hideOnClick={hideOnClick}
            manufacturers={manufacturers}
          />
        </CategoriesBrandsListing>
      </ModalWrapper>
    </MediaContextProvider>
  )
}

export default ProductSearchPopUp

const ModalWrapper = styled(Modal)`
  color: ${(props) => props.theme.colors.luigiMainColor};
  z-index: 1001;
  position: absolute;
  top: 130px;
  left: 400px;
  width: 900px;
  height: 520px;
  border: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
  overflow: hidden;
  ${validFor.desktop`
    left: 50%;
    transform: translateX(-60%);
  `}
  ${validFor.tablet`
  ${(props) => (props.isSticky ? 'top: 70px;' : 'top: 110px;')}
    left: 40px;
    min-height: 730px;
    min-width: 90%;
  `};
  ${validFor.mobile`
    min-width: 90vw;
    min-height: 90vh;
    ${(props) =>
      props.showHeaderPromo !== null &&
      props.isHeaderNoticeEnabled &&
      !props.isSticky
        ? 'top: 110px;'
        : 'top: 60px;'}
    left: 20px;
    overflow-y: visible;
    position: scroll;
  `};
`

const ProductListing = styled(Flex)`
  width: 600px;
  background-color: ${(props) => props.theme.colors.menuBackground};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 13px;
  ${validFor.tablet`
    width: 100%;
    padding: 0;
  `};
  ${validFor.mobile`
    width: 100%;
    padding: 0;
  `};
`

const NoResultWrap = styled(Flex)`
  flex-direction: column;
  padding-left: 20px;
`

const NoResultTitle = styled(H2)`
  font-weight: 500;
  margin-bottom: 12px;
  ${validFor.mobile`
   font-size: 20px;
  `};
`
const NoResultSubTitle = styled(Body3)`
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 8px;
`

const CategoriesBrandsListing = styled(Flex)`
  width: 300px;
  background-color: ${(props) => props.theme.colors.menuBackground};
  flex-direction: column;
  align-items: flex-start;
  padding: 13px;
  ${validFor.tablet`
    width: 100%;
    padding: 0;
  `};
  ${validFor.mobile`
    width: 100%;
    padding-bottom: 30px;
  `};
`

const ProductItems = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  ${validFor.mobile`
    flex-wrap:nowrap;
    flex-direction: column;
  `};
`

const ButtonWrapper = styled(Flex)`
  margin: 0 auto;
  padding-top: 20px;
  ${validFor.mobile`
    margin-top:20px;
  `};
`

const StyledProductTitle = styled(Flex)`
  font-size: 20px;
  padding: 13px 20px;
`

const StyledLuigiButton = styled(BigAccentButton)`
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.menuBackground};
  margin-bottom: 15px;
  width: 300px;
`
const ProductSearchCaret = styled(Box)`
  position: fixed;
  z-index: 1000;
  top: 125px;
  left: 450px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.colors.menuBackground};
  transform: rotate(45deg);
  border-top: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
  border-left: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
  ${validFor.desktop`
    position: fixed;
    top: 125px;
    left: 29%;
  `}
  ${validFor.ultraWide`
    position: fixed;
    top: 125px;
    left: 37%;
  `}
  ${validFor.tablet`
    background-color: ${(props) => props.theme.colors.menuBackground};
    border-top: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
    border-left: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
  ${(props) => (props.isSticky ? 'top: 64px;' : 'top: 104px;')}
    left: 60px;
  `};
  ${validFor.mobile`
    background-color: ${(props) => props.theme.colors.menuBackground};
    border-top: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
    border-left: 1px solid ${(props) => props.theme.colors.toolbarSeparator};
     ${(props) =>
       props.showHeaderPromo !== null &&
       props.isHeaderNoticeEnabled &&
       !props.isSticky
         ? 'top: 105px;'
         : 'top: 55px;'}
    left: 30px;
  `};
`
