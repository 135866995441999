import { Routes } from '@/core/routes'
import MobileHeaderButton from '@/components/molecules/mobileHeaderButton'
import { FC } from 'react'
import {
  Desktop,
  MediaContextProvider,
  Mobile,
  Tablet
} from '@/components/atoms/media'
import { useTheme } from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { useUser } from '@/providers/userProvider'
import DesktopHeaderButton from '@/components/molecules/desktopHeaderButton'
import ListLoginButton from '@/components/molecules/listLoginButton'
import MenuLogoutPart from '@/components/atoms/menuLogoutPart'
import { Flex } from '@/components/atoms/Grid'
import { useRouter } from 'next/router'
import { UserIconDefault } from '../atoms/icons/UserIconDefault'

export enum LoginButtonType {
  headerButton,
  listButton
}

type LoginButtonProps = {
  type?: LoginButtonType
} & Rest

const LoginButton: FC<LoginButtonProps> = ({
  type = LoginButtonType.headerButton,
  ...rest
}) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const { user, isLoggedIn } = useUser()
  const isSigned = isLoggedIn && !!user?.id
  const router = useRouter()
  const route = isSigned
    ? Routes.orderHistory
    : `${Routes.login}/?referer=${encodeURI(router.asPath)}`
  const title = isSigned ? `${user.firstname} ${user.lastname}` : null

  return type === LoginButtonType.headerButton ? (
    <MediaContextProvider>
      <Desktop>
        <Flex maxWidth={200} data-cy="headerLoginButton" {...rest}>
          <DesktopHeaderButton
            hrefLink={route}
            title={title || t('Toolbar.Buttons.login')}
            IconElement={<UserIconDefault color={theme.colors.onBackground} />}
            data-cy={isSigned}
            p={0}
          />
          {isSigned && <MenuLogoutPart />}
        </Flex>
      </Desktop>
      <Tablet>
        <MobileHeaderButton
          data-cy={route}
          hrefLink={route}
          IconElement={<UserIconDefault color={theme.colors.onBackground} />}
          {...rest}
        />
      </Tablet>
      <Mobile>
        <MobileHeaderButton
          data-cy={route}
          hrefLink={route}
          IconElement={<UserIconDefault color={theme.colors.background} />}
          {...rest}
        />
      </Mobile>
    </MediaContextProvider>
  ) : (
    <div data-cy="mobileLoginMenuButton" {...rest}>
      <ListLoginButton
        hrefLink={route}
        title={title || t('Login.buttonTitle')}
        isSigned={isSigned}
      />
    </div>
  )
}

export default LoginButton
