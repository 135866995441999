import { FC } from 'react'
import { SvgProps } from './svgProps'

const MenuDrawer: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '18'}
    height={height || '14'}
    viewBox="0 0 18 14"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={color || '#FFF'}>
        <g transform="translate(-844 -39) translate(844 39)">
          <rect width="18" height="1.5" rx=".75" />
          <rect width="9" height="1.5" y="6" rx=".75" />
          <rect width="16" height="1.5" y="12" rx=".75" />
        </g>
      </g>
    </g>
  </svg>
)

export default MenuDrawer
