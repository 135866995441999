import { FC } from 'react'
import { Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'
import { Tiny2 } from '@/components/atoms/typography'
import Link from '@/components/atoms/link'
import Badge from '@/components/atoms/badge'

type DesktopHeaderButtonProps = {
  title: string
  hrefLink: string
  notifyCount?: number
  IconElement: JSX.Element
} & Rest

const DesktopHeaderButton: FC<DesktopHeaderButtonProps> = ({
  title,
  hrefLink,
  notifyCount,
  IconElement,
  children,
  ...rest
}) => (
  <>
    <Link href={hrefLink}>
      <StyledFlex {...rest}>
        {IconElement}
        <StyledTitle>{title}</StyledTitle>
        {notifyCount && notifyCount > 0 ? (
          <StyledBadge counter={notifyCount} ml="5px" />
        ) : null}
      </StyledFlex>
    </Link>
    {children}
  </>
)

export default DesktopHeaderButton

const StyledBadge = styled(Badge)`
  padding: 4px;
`
const StyledTitle = styled(Tiny2)`
  padding: 0 0 0 8px;
  justify-content: center;
  opacity: 0.6;
`

const StyledFlex = styled(Flex)`
  flex-direction: row;
  align-items: center;
  &:hover {
    ${StyledTitle} {
      opacity: 1;
    }
    cursor: pointer;
  }
`
