import { FC } from 'react'
import { SvgProps } from './svgProps'

const ArrowDownIcon: FC<SvgProps> = ({ width, height, color, opacity }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 12}
    height={height || 9}
    viewBox="0 0 12 9"
  >
    <use
      href="#arrow-down-icon-definition"
      stroke={color || '#000'}
      opacity={opacity || 0.538}
    />
  </svg>
)

export default ArrowDownIcon
