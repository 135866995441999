import { FC } from 'react'
import { Tiny2 } from '@/components/atoms/typography'
import { ListItemSeparator } from '@/components/atoms/separator'
import { Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'
import { SlugPathItem } from '@/types/slugPath'

type ListItemProps = {
  id: string | number
  title: string
  onClick?: () => void
  highlight?: boolean
  separator?: boolean
  isActive?: SlugPathItem
}

const ListItem: FC<ListItemProps> = ({
  id,
  title,
  onClick,
  highlight,
  separator = true,
  isActive,
  ...rest
}) => (
  <StyledListItem onClick={onClick} {...rest}>
    <StyledTiny2
      py="2px"
      pl={12}
      key={id}
      highlight={highlight}
      isActive={isActive}
    >
      {title}
    </StyledTiny2>
    {separator && <ListItemSeparator />}
  </StyledListItem>
)

export default ListItem

const StyledListItem = styled(Flex)`
  min-height: 36px;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`
const StyledTiny2 = styled(Tiny2)`
  ${(props) => (props.highlight ? 'font-weight: bold;' : 'opacity: 0.6;')};
  margin-bottom: ${(props) => (props.isActive ? '10px' : '12px')};
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    width: 12px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.onBackground};
  }
`
