import { validFor } from '@/theme/validFor'
import React, { FC } from 'react'
import { SectionSeparator } from '@/components/atoms/separator'
import { Capitalized } from '@/components/atoms/typography'
import styled, { css } from 'styled-components'
import { textAlign, margin, padding } from 'styled-system'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & td,
  & th {
    vertical-align: middle;
    max-width: 400px;
    min-width: 40px;
    padding-left: 4px;
    padding-right: 4px;

    ${validFor.mobile`
      padding-left: 6px;
      padding-right: 6px;
    `}

    ${validFor.desktopWide`
     padding-left: 14px;
      padding-right: 14px;
    `}
  }
  & td:first-child,
  & th:first-child {
    word-break: break-word;

    padding-left: 4px;
    ${validFor.mobile`
      padding-left: 12px;
    `}

    ${validFor.desktopWide`
       padding-left: 26px;
    `}
  }
  & td:last-child,
  & th:last-child {
    min-width: 100px;
    padding-right: 4px;
    ${validFor.mobile`
      padding-right: 12px;
    `}

    ${validFor.desktopWide`
      padding-right: 26px;
    `}
  }
  ${margin};
`

const Bordered = css`
  ${(props) =>
    !props.customLayout
      ? `border: 1px solid ${props.theme.colors.sectionSeparator};`
      : ''}
  ${validFor.mobile`
      ${(props) =>
        !props.customLayout
          ? `background-color: ${props.theme.colors.background};`
          : ''}
  `}
`

export const Head = styled.thead`
  ${Bordered}
`

export const Body = styled.tbody`
  ${Bordered}
  & tr:last-child {
    display: none;
  }
`

export const HeadRow: FC = ({ children }) => <tr>{children}</tr>

export const BodyRow: FC = ({ children, ...rest }) => (
  <>
    <tr {...rest}>{children}</tr>
    <tr>
      <td colSpan={React.Children.toArray(children).length}>
        <SectionSeparator />
      </td>
    </tr>
  </>
)

type CellProps = {
  textAlign?: string
} & Rest

export const BodyCell: FC<CellProps> = ({
  textAlign = 'left',
  children,
  ...rest
}) => (
  <Td textAlign={textAlign} {...rest}>
    {children}
  </Td>
)

export const HeadCell: FC<CellProps> = ({
  textAlign = 'left',
  children,
  ...rest
}) => (
  <Th textAlign={textAlign} {...rest}>
    <ThText>{children}</ThText>
  </Th>
)

const Th = styled.th`
  padding: 0;
  ${textAlign};
`

const Td = styled.td`
  padding-top: 20px;
  padding-bottom: 20px;
  ${validFor.mobile`
    padding-top: 15px;
    padding-bottom: 15px;
  `}
  ${textAlign};
  &&& {
    ${padding};
  }
`

const ThText = styled(Capitalized)`
  text-transform: uppercase;
  opacity: 0.6;
`
