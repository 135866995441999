import { FC, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import MobileHeaderButton from '@/components/molecules/mobileHeaderButton'
import { IconButtonCapitalized } from '@/components/molecules/iconButtons'
import {
  ToolbarProps,
  TabletOrMobileToolbarProps
} from '@/components/organisms/toolbar/toolbar'
import useTranslation from 'next-translate/useTranslation'
import ToolbarLinkLogo from '@/components/molecules/toolbarLinkLogo'
import { Routes } from '@/core/routes'
import { FocusMode, useCurrentPage } from '@/providers/focusModeProvider'
import LoginButton from '@/components/molecules/loginButton'
import { SectionSeparator } from '@/components/atoms/separator'
import { useOrder } from '@/providers/order/old/orderProvider'
import { ReactHeight } from 'react-height'
import { ITheme } from '@/theme/interfaces/ITheme'
import { MenuDrawerDefault } from '@/components/atoms/icons/MenuDrawerDefault'
import { SearchIconDefault } from '@/components/atoms/icons/SearchIconDefault'
import { WishlistIconDefault } from '@/components/atoms/icons/WishlistIconDefault'
import { CartIconDefault } from '@/components/atoms/icons/CartIconDefault'
import ProductSearch from '@/components/molecules/productSearch'
import { useLuigiTracker } from '@/providers/luigiTrackerProvider'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import SlideMenuAdditionalInfo from '../slideMenuAdditionalInfo'
import SwiperMenuAdd from '../swiperMenuAdd'
import ProductSearchPopUpWrapper from '@/components/molecules/productSearch/productSearchPopUpWrapper'
import { useAsset } from '@/providers/assetsProvider'
import { ChristmasGiftBoxIconDefault } from '@/components/atoms/icons/ChristmasGiftBoxIconDefault'

type TabletToolbarWrapperProps = ToolbarProps &
  TabletOrMobileToolbarProps & { theme?: ITheme }

const TabletToolbarWrapper: FC<TabletToolbarWrapperProps> = ({ ...rest }) => {
  const [toolbarHeight, setToolbarHeight] = useState<number>(0)

  return (
    <Wrapper toolbarHeight={toolbarHeight}>
      <ReactHeight onHeightReady={(height) => setToolbarHeight(height)}>
        <TabletToolbar {...rest} />
      </ReactHeight>
    </Wrapper>
  )
}

const TabletToolbar: FC<TabletToolbarWrapperProps> = ({
  theme,
  onDrawerClick,
  wishlistProductCount
}) => {
  const { t } = useTranslation('common')
  const { getFocusType } = useCurrentPage()
  const isBasicFocus = getFocusType() === FocusMode.Basic
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false)
  const display = isBasicFocus ? 'none' : 'inherit'
  const { count } = useOrder()
  const { isLuigiSearchSupported } = useLuigiTracker()
  const { show, showAdditionalMenu, showAdditionalMenuOnHP } =
    useAdditionalInfoProvider()
  const { isChristmasPromoTime, isShutDownCountry } = useAsset()

  return (
    <Box>
      <StyledFlex>
        <InlineWrapper justifyContent="flex-start" display={display}>
          <StyledIconButton
            onClick={onDrawerClick}
            title={t('Toolbar.Buttons.hamburger')}
            IconElement={
              <MenuDrawerDefault color={theme.colors.onBackground} />
            }
          />
        </InlineWrapper>
        <Flex width={isBasicFocus ? 'unset' : '33%'} justifyContent="center">
          <ToolbarLinkLogo />
        </Flex>
        <InlineWrapper justifyContent="flex-end" height="35px">
          <Box>
            <LoginButton />
          </Box>
          {!isShutDownCountry && (
            <>
              <Box display={display}>
                <MobileHeaderButton
                  data-cy={Routes.wishlist}
                  hrefLink={Routes.wishlist}
                  IconElement={
                    isChristmasPromoTime ? (
                      <ChristmasGiftBoxIconDefault
                        color={theme.colors.onBackground}
                      />
                    ) : (
                      <WishlistIconDefault color={theme.colors.onBackground} />
                    )
                  }
                  notifyCount={wishlistProductCount}
                />
              </Box>

              <Box display={display}>
                <StyledSearchButton
                  onClick={() => {
                    setShowSearchInput(true)
                  }}
                  IconElement={
                    <SearchIconDefault color={theme.colors.onBackground} />
                  }
                />
              </Box>
              <Box>
                <MobileHeaderButton
                  data-cy={Routes.cart}
                  hrefLink={Routes.cart}
                  IconElement={
                    <CartIconDefault color={theme.colors.onBackground} />
                  }
                  notifyCount={count}
                />
              </Box>
            </>
          )}
        </InlineWrapper>
        {show && <SlideMenuAdditionalInfo />}
        {showAdditionalMenu || showAdditionalMenuOnHP ? (
          <SwiperMenuAdd />
        ) : null}
      </StyledFlex>
      <StyledSectionSeparator />
      <SearchWrapper show={showSearchInput}>
        {isLuigiSearchSupported ? (
          <ProductSearch
            onInputFocusChanged={setShowSearchInput}
            focusOnMount={true}
            canHideAsPopup={true}
            isVisibleAsPopup={showSearchInput}
          />
        ) : (
          <ProductSearchPopUpWrapper />
        )}
      </SearchWrapper>
    </Box>
  )
}

export default withTheme(TabletToolbarWrapper)

const Wrapper = styled(Box)`
  position: relative;
`

const StyledFlex = styled(Flex)`
  background: ${(props) => props.theme.colors.background};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 65px;
  width: 100%;
`

const StyledIconButton = styled(IconButtonCapitalized)`
  border: none;
`

const StyledSearchButton = styled(IconButtonCapitalized)`
  border: none;
  padding: 10px;
`

const InlineWrapper = styled(Flex)`
  flex-direction: row;
  width: 33.3%;
`

const SearchWrapper = styled.div`
  background-color: black;
  justify-content: stretch;
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  border-radius: ${(props) => props.theme.borderRadius}px;
`
const StyledSectionSeparator = styled(SectionSeparator)`
  ${(props) => props.theme.separatorWidth}
  margin: 0 auto;
`
