import { FC } from 'react'
import Badge from '@/components/atoms/badge'
import Link from '@/components/atoms/link'
import { Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'

type MobileHeaderButton = {
  hrefLink: string
  notifyCount?: number
  IconElement: JSX.Element
} & Rest

const MobileHeaderButton: FC<MobileHeaderButton> = ({
  hrefLink,
  notifyCount,
  IconElement,
  ...rest
}) => {
  return (
    <Link href={hrefLink}>
      <StyledFlex {...rest}>
        {IconElement}
        {notifyCount && notifyCount > 0 ? (
          <StyledBadge counter={notifyCount} />
        ) : null}
      </StyledFlex>
    </Link>
  )
}
export default MobileHeaderButton

const StyledFlex = styled(Flex)`
  flex-direction: column;
  margin: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  left: 50%;
  bottom: 50%;
`
