import { SectionSeparator } from '@/components/atoms/separator'
import { Tiny2 } from '@/components/atoms/typography'
import { FC } from 'react'
import Flex from '@/components/atoms/Grid/Flex'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { validFor } from '@/theme/validFor'
import { useAuth } from '@/providers/authProvider'

const MenuLogoutPart: FC<Rest> = ({ ...rest }) => {
  const { t } = useTranslation('common')
  const { logout } = useAuth()

  return (
    <ButtonWrapper
      onClick={(e) => {
        e.preventDefault()
        logout()
      }}
      data-cy="logout"
      {...rest}
    >
      <SectionSeparator height={15} isVertical mx="7px" />
      <StyledTiny2>{t('Menu.logout')}</StyledTiny2>
    </ButtonWrapper>
  )
}

export default MenuLogoutPart

const ButtonWrapper = styled(Flex)`
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

const StyledTiny2 = styled(Tiny2)`
  margin: 0;
  opacity: 0.6;
  ${validFor.desktop`
    &:hover {
      opacity: 1;
    }
  `}
`
