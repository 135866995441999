import useTranslation from 'next-translate/useTranslation'

export const ProductSearchBrands = (): {
  id: number
  title: string
  url: string
}[] => [
  {
    id: 1,
    title: 'Frogies',
    url: '/frogies'
  },
  {
    id: 2,
    title: 'Lee Cooper',
    url: '/lee-cooper'
  },
  {
    id: 3,
    title: 'Trendyol',
    url: '/trendyol'
  },
  {
    id: 4,
    title: 'Ombre',
    url: '/ombre'
  },
  {
    id: 5,
    title: 'NUMOCO',
    url: '/numoco'
  },
  {
    id: 6,
    title: 'Koton',
    url: '/koton'
  },
  {
    id: 7,
    title: 'Adidas',
    url: '/adidas'
  },
  {
    id: 8,
    title: 'Under Armour',
    url: '/under-armour'
  },
  {
    id: 9,
    title: 'Fashionhunters',
    url: '/fashionhunters'
  }
]

export const ProductSearchCategories = (): {
  id: number
  title: string
  url: string
}[] => {
  const { t } = useTranslation('common')
  return [
    {
      id: 1,
      title: t('Search.categoriesTitle1'),
      url: '/zeny/zeny-oblecenie/saty'
    },
    {
      id: 2,
      title: t('Search.categoriesTitle2'),
      url: '/zeny/zeny-oblecenie/tricka-damske'
    },
    {
      id: 3,
      title: t('Search.categoriesTitle3'),
      url: '/zeny/zeny-oblecenie/damske-mikiny'
    },
    {
      id: 4,
      title: t('Search.categoriesTitle4'),
      url: '/muzi/muzi-oblecenie/tricka-panske'
    },
    {
      id: 5,
      title: t('Search.categoriesTitle5'),
      url: '/muzi/muzi-oblecenie/mikiny-panske'
    },
    {
      id: 6,
      title: t('Search.categoriesTitle6'),
      url: '/muzi/muzi-oblecenie/bundy-panske'
    },
    {
      id: 7,
      title: t('Search.categoriesTitle7'),
      url: '/muzi/muzi-oblecenie/spodne-pradlo'
    },
    {
      id: 8,
      title: t('Search.categoriesTitle8'),
      url: '/category/425'
    },
    {
      id: 9,
      title: t('Search.categoriesTitle9'),
      url: '/category/397'
    }
  ]
}

export const ProductSearchItems = (): {
  id: string
  image: string
  name: string
  secondPrice: number
  finalPrice: number
}[] => {
  const { t } = useTranslation('common')
  return [
    {
      id: '1975973',
      image: 'catalog/products/60/1d/2b/60-1d-2bad1e8edsc-0179.jpg',
      name: `${t('Search.productTitle1')} FASARDI`,
      secondPrice: 78.99,
      finalPrice: 32.92
    },
    {
      id: '1516454',
      image:
        'catalog/products/6/4e/296-4e-2995339b20210928151125_9911111169511.jpg',
      name: `${t('BrandsLandingPage.Banners.Socks')} Kama Sutra`,
      secondPrice: 8.99,
      finalPrice: 3.37
    },
    {
      id: '1923807',
      image:
        'catalog/products/6/3c/c06-3c-c038dad620220523104204_9911111186792-1.jpg',
      name: `${t('Search.productTitle1')} Frogies`,
      secondPrice: 19.99,
      finalPrice: 10.19
    },
    {
      id: '1440141',
      image:
        'catalog/products/6/6d/016-6d-0178cfcf20210903163123_P210810155939_top_RAW_275.jpg',
      name: `${t('Search.productTitle2')} Lee Cooper`,
      secondPrice: 19.99,
      finalPrice: 8.77
    },
    {
      id: '1948355',
      image:
        'catalog/products/6/23/aa6-23-aa5d691120220719073604_9911111184446.jpg',
      name: `${t('Search.productTitle3')} Lee Cooper`,
      secondPrice: 26.99,
      finalPrice: 14.96
    },
    {
      id: '1952227',
      image:
        'catalog/products/6/ee/c66-ee-c69b4b4a20221121064841_9911111201006.jpg',
      name: `${t('Search.productTitle3')} Frogies`,
      secondPrice: 59.99,
      finalPrice: 28.12
    }
  ]
}
