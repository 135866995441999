import { FC } from 'react'
import styled from 'styled-components'
import { useUser } from '@/providers/userProvider'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import { CategoriesIconDefault } from '../atoms/icons/CategoriesIconDefault'

type SwiperMenuAddColorProps = {
  color?: string
}

const SwiperMenuAdd: FC<SwiperMenuAddColorProps> = ({
  color = `currentColor`
}) => {
  const { isEmployee } = useUser()
  const { handleChange } = useAdditionalInfoProvider()
  return (
    <>
      {isEmployee && (
        <IconWrapper onClick={handleChange}>
          <CategoriesIconDefault color={color} p={2} size={20} />
        </IconWrapper>
      )}
    </>
  )
}

export default SwiperMenuAdd

const IconWrapper = styled.div`
  display: block;
  &:hover {
    cursor: pointer;
  }
`
