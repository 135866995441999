import {
  Desktop,
  MediaContextProvider,
  Mobile,
  Tablet
} from '@/components/atoms/media'
import type { FC } from 'react'
import DesktopToolbar from '@/components/organisms/toolbar/desktop/desktopToolbar'
import MobileToolbar from '@/components/organisms/toolbar/mobile/mobileToolbar'
import TabletToolbar from '@/components/organisms/toolbar/tabletToolbar'
import { useWishlist } from '@/providers/wishlistProvider'
import { HeaderNotice } from '@/network/graphql.g'

export type ToolbarProps = {
  onDrawerClick?: () => void
  showHeaderPromo: boolean
  isSticky?: boolean
  headerNotice?: HeaderNotice
  isHeaderNoticeEnabled?: boolean
  isSearchbarOpen?: boolean
}

export type TabletOrMobileToolbarProps = {
  wishlistProductCount: number
  showHeaderPromo: boolean
}

const Toolbar: FC<ToolbarProps> = ({
  onDrawerClick,
  showHeaderPromo,
  headerNotice,
  isSearchbarOpen
}) => {
  const { wishlistProductCount } = useWishlist()

  return (
    <MediaContextProvider>
      <Desktop>
        <DesktopToolbar wishlistProductCount={wishlistProductCount} />
      </Desktop>
      <Tablet>
        <TabletToolbar
          onDrawerClick={onDrawerClick}
          wishlistProductCount={wishlistProductCount}
        />
      </Tablet>
      <Mobile>
        <MobileToolbar
          onDrawerClick={onDrawerClick}
          wishlistProductCount={wishlistProductCount}
          showHeaderPromo={showHeaderPromo}
          headerNotice={headerNotice}
          isSearchbarOpen={isSearchbarOpen}
        />
      </Mobile>
    </MediaContextProvider>
  )
}

export default Toolbar
