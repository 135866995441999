import { FC, useContext } from 'react'
import MobileMenuBox from '@/components/molecules/mobileMenuBox'
import { Box, Flex } from '@/components/atoms/Grid'
import MenuTextItem from '@/components/atoms/menuTextItem'
import Link from '@/components/atoms/link'
import LoginButton, {
  LoginButtonType
} from '@/components/molecules/loginButton'
import { HomeBanner, Market, NavigationItem } from '@/network/graphql.g'
import MenuBadge from '@/components/atoms/menuBadge'
import MarketDropdown, {
  MarketDropdownMode
} from '@/components/molecules/marketDropdown'
import { StoreContext } from '@/providers/storeProvider'
import { applyImagePolicy } from '@/utils/imageExtension'
import styled, { useTheme } from 'styled-components'
import { DiscountCouponIconDefault } from '@/components/atoms/icons/DiscountCouponIconDefault'
import { IconButtonReverse } from '@/components/molecules/iconButtons/iconButton'
import { useAsset } from '@/providers/assetsProvider'
import { useMainNavigationDispatch } from '@/providers/mainNavigationMenuProvider'

type MainMobileCategoryMenuProps = {
  navigationItems: NavigationItem[]
  otherMenuItems: NavigationItem[]
  onClick: (
    id: string,
    subcategories: NavigationItem[],
    banners: HomeBanner[]
  ) => void
  markets: Market[]
}

const MobileCategoriesMenu: FC<MainMobileCategoryMenuProps> = ({
  navigationItems,
  otherMenuItems,
  onClick,
  markets
}) => {
  const { isBezvasport } = useContext(StoreContext)
  const theme = useTheme()
  const { isShutDownCountry } = useAsset()
  const { slugPath } = useMainNavigationDispatch()
  const frogiesItem = navigationItems.find((item) => item.name === 'Frogies')
  if (frogiesItem) {
    frogiesItem.categoryId = '999999'
  }

  return (
    <Flex flexDirection="column" py={15}>
      {!isShutDownCountry && (
        <>
          <Box mb={20} data-cy="mobileCategoryMenu">
            {navigationItems.map(
              ({
                name,
                isMainCategory,
                url,
                isHighlight,
                children,
                categoryId,
                mobileBadge,
                desktopBadge,
                homeBanners,
                isBigButton
              }) => (
                <MobileMenuBox
                  key={url}
                  title={name}
                  imageUrl={
                    isMainCategory
                      ? applyImagePolicy(`/static/mob-cat-${categoryId}.jpg`)
                      : null
                  }
                  onClick={() => onClick(categoryId, children, homeBanners)}
                  mb={10}
                  data-cy="mobileCategoryMenuItem"
                  highlight={isHighlight}
                  badge={mobileBadge || desktopBadge}
                  isActive={slugPath.find((path) => path.id === categoryId)}
                  isBigButton={isBigButton}
                />
              )
            )}
          </Box>
          <Box>
            <Flex flexDirection="column" data-cy="mobileMenuOthersItems">
              {otherMenuItems &&
                otherMenuItems.map(
                  ({
                    name,
                    url,
                    isHighlight,
                    mobileBadge,
                    desktopBadge,
                    isBigButton
                  }) => (
                    <Link href={url} key={name}>
                      {(mobileBadge || desktopBadge) && (
                        <MenuBadge
                          imageUrl={mobileBadge || desktopBadge}
                          maxHeight={30}
                          mr={10}
                        />
                      )}
                      {isBigButton ? (
                        <>
                          <StyledIconButtonReverse
                            title={name.toUpperCase()}
                            IconElement={
                              <DiscountCouponIconDefault
                                color={theme.colors.accent}
                                secondarycolor={theme.colors.menuBackground}
                              />
                            }
                          />
                        </>
                      ) : (
                        <MenuTextItem
                          lineHeight={0.2}
                          highlight={isHighlight}
                          data-cy={url}
                        >
                          {name.toUpperCase()}
                        </MenuTextItem>
                      )}
                    </Link>
                  )
                )}
            </Flex>
          </Box>
        </>
      )}

      <Box mt={14} mb={12}>
        <LoginButton type={LoginButtonType.listButton} />
      </Box>
      {!isBezvasport && !isShutDownCountry && (
        <MarketDropdown
          markets={markets}
          mode={MarketDropdownMode.SideMenu}
        ></MarketDropdown>
      )}
    </Flex>
  )
}

export default MobileCategoriesMenu

const StyledIconButtonReverse = styled(IconButtonReverse)`
  background-color: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.menuBackground};
  width: 100%;
  min-height: 50px;
  //margin-top: -20px;
  //margin-bottom: 20px;
  &:hover {
    background-color: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.menuBackground};
  }
`
