import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex } from 'components/atoms/Grid'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Routes } from '@/core/routes'
import { autoCompleteInit } from 'scripts/luigi'
import { useLuigiTracker } from '@/providers/luigiTrackerProvider'
import { validFor } from '@/theme/validFor'
import { ThirdPartyScripts } from '@/core/thirdparty/map'
import { SearchIconDefault } from '@/components/atoms/icons/SearchIconDefault'
import { ScriptProps } from 'hooks/useScript'

type ProductSearchProps = {
  onInputFocusChanged?: (hasFocus: boolean) => void
  canHideAsPopup?: boolean
  isVisibleAsPopup?: boolean
  isSearchbarOpen?: boolean
  isDesktop?: boolean
} & Rest

const ProductSearch: FC<ProductSearchProps> = ({
  onInputFocusChanged,
  canHideAsPopup,
  isVisibleAsPopup,
  isSearchbarOpen,
  isDesktop,
  ...rest
}) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const isHovered = useRef(false)
  const isDropdownHovered = useRef(false)
  const inputRef = useRef(null)
  const router = useRouter()
  const { t } = useTranslation('common')
  const { luigiTrackerId, isLuigiSearchSupported } = useLuigiTracker()
  const [luigisScriptLoad, setluigisScriptLoad] = useState<boolean>(false)

  useEffect(() => {
    if (inputRef) {
      inputRef.current.value = router.query?.search ?? ''
    }
  }, [router.query?.search])

  useEffect(() => {
    if (isVisibleAsPopup) {
      inputRef.current.focus()
    }
  }, [isVisibleAsPopup])

  const loadLuigiScript = (): void => {
    const propsConfigData: ScriptProps = {
      content: autoCompleteInit(luigiTrackerId, t, isDesktop)
    }

    const propsJS: ScriptProps = {
      scriptPath: ThirdPartyScripts.luigisboxAutocompleteJs,
      attrs: [
        { name: 'data-luigisbox-tracker-id', value: luigiTrackerId },
        {
          name: 'onload',
          value: 'LBInitAutocomplete()'
        }
      ],
      deps: [router]
    }

    loadJS(propsConfigData)
    loadJS(propsJS)
  }

  const loadJS = (props: ScriptProps) => {
    if (props === null) {
      return
    }
    const script = document.createElement('script')
    if (props.scriptPath) {
      script.src = props.scriptPath
    }

    props.attrs &&
      props.attrs.forEach((attribute) => {
        script.setAttribute(attribute.name, attribute.value)
      })

    script.async = props.async
    script.textContent = props.content
    document.body.appendChild(script)

    return () => {
      try {
        document.body.removeChild(script)
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }

  const submitSearch = (e) => {
    e.preventDefault()
    if (!inputRef.current.value) {
      inputRef.current.focus()
      return
    }
    router.push({
      pathname: Routes.search,
      query: { search: inputRef.current.value }
    })
    if (canHideAsPopup) {
      onFocusChanged(false, false)
    } else {
      handleFocus(false)
    }
  }

  const onFocusChanged = (hasFocus: boolean, usePopup: boolean) => {
    if (!luigisScriptLoad && isLuigiSearchSupported) {
      setluigisScriptLoad(true)
      loadLuigiScript()
    }

    if (
      (!hasFocus && usePopup && isHovered.current) ||
      isDropdownHovered.current
    ) {
      return
    }
    handleFocus(hasFocus)
  }

  const handleFocus = (hasFocus: boolean, clearDropdownFocus = false) => {
    if (clearDropdownFocus) {
      isDropdownHovered.current = false
    }
    setShowOverlay(hasFocus)
    {
      onInputFocusChanged && onInputFocusChanged(hasFocus)
    }
  }

  const handleHover = (isHover: boolean) => {
    canHideAsPopup && (isHovered.current = isHover)
  }

  return (
    <StyledForm onSubmit={submitSearch} key={router.asPath}>
      <StyledFlex
        onMouseEnter={canHideAsPopup ? () => handleHover(true) : null}
        onMouseLeave={() => handleHover(false)}
        {...rest}
      >
        <StyledSearchInput
          ref={inputRef}
          id="autocompleteInput"
          type="text"
          name="productSearch"
          placeholder={t('Toolbar.Search.inputPlaceholder')}
          data-cy="headerInputSearch"
          className={'fulltext-autocomplete'}
          onFocus={() => onFocusChanged(true, canHideAsPopup)}
          onBlur={() => onFocusChanged(false, canHideAsPopup)}
          isSearchbarOpen={isSearchbarOpen}
        />
        <StyledSearchButton type="submit" data-cy="headerButtonSearch">
          <SearchIconDefault />
        </StyledSearchButton>
      </StyledFlex>
      <SearchOverlay show={showOverlay} id="luigi_search_input_overlay" />
    </StyledForm>
  )
}

export default ProductSearch

const SearchOverlay = styled.div`
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.3s ease-out;
  opacity: ${(props) => (props.show ? '0.2' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`

const StyledFlex = styled(Flex)`
  min-width: 420px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${validFor.mobile` 
    min-width: 0px;
	`};
`

const StyledSearchInput = styled.input`
  z-index: 3;
  ${(props) => props.theme.headerSearchInput && props.theme.headerSearchInput}
  height: 36px;
  flex-grow: 1;
  margin: 0;
  padding: 0 13px;
  border-top-left-radius: ${(props) => props.theme.borderRadius}px;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius}px;
  border: ${(props) => props.isSearchbarOpen && 'none'};
  &::placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholder};
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholder};
  }
  &:focus {
    outline-style: none;
    border: ${(props) => props.isSearchbarOpen && 'none'};
  }
`

const StyledSearchButton = styled.button`
  z-index: 3;
  height: 38px;
  width: 38px;
  background: ${(props) => props.theme.colors.headerButton};
  border: none;
  margin: 0;
  outline: black;
  align-self: end;
  border-top-right-radius: ${(props) => props.theme.borderRadius}px;
  border-bottom-right-radius: ${(props) => props.theme.borderRadius}px;
  ${(props) => props.theme.headerSearchButton && props.theme.headerSearchButton}
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.accent};
  }
`

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`
