import { FC } from 'react'
import { SvgProps } from './svgProps'

const SearchIcon: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 17}
    height={height || 18}
    viewBox="0 0 17 18"
    {...rest}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H16V16H0z"
            transform="translate(-803 -37) translate(803 37)"
          />
          <g
            stroke={color || '#FFF'}
            strokeLinecap="round"
            strokeWidth="1.5"
            transform="translate(-803 -37) translate(803 37) translate(1.067 1.067)"
          >
            <circle cx="6.4" cy="6.4" r="6.4" />
            <path d="M14.667 15.333L10.852 11.519" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SearchIcon
