import { FC, useEffect, useRef, useState } from 'react'
import useDisableScroll from '../../../hooks/useDisableScroll'
import {
  FilterType,
  FullTextProduct,
  ItemsForFullTextProduct,
  Sdk
} from '@/network/graphql.g'
import useGraphQl from '../../../hooks/useGraphQl'
import { useMhub } from '@/providers/mhubProvider'
import ProductSearchPopUp from '@/components/molecules/productSearch/productSearchPopUp'
import { Routes } from '@/core/routes'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { SearchIconDefault } from '@/components/atoms/icons/SearchIconDefault'
import { validFor } from '@/theme/validFor'
import { Flex } from '@/components/atoms/Grid'
import useTranslation from 'next-translate/useTranslation'

type ProductSearchPopUpWrapper = {
  isSticky?: boolean
  setShowSearchInput?: (boolean) => void
  focusOnMount?: boolean
  isVisibleAsPopup?: boolean
  isHeaderNoticeEnabled?: boolean
  showHeaderPromo?: boolean
}

const ProductSearchPopUpWrapper: FC<ProductSearchPopUpWrapper> = ({
  isSticky,
  setShowSearchInput,
  focusOnMount,
  isVisibleAsPopup,
  isHeaderNoticeEnabled,
  showHeaderPromo
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [showPopUp, setShowPopUp] = useState<boolean>(true)
  const abortController = useRef<AbortController>(null)
  const [searchProducts, setSearchProducts] = useState<FullTextProduct[]>([])
  const [searchManufacturers, setSearchManufacturers] = useState<
    ItemsForFullTextProduct[]
  >([])
  const [searchCategories, setSearchCategories] = useState<
    ItemsForFullTextProduct[]
  >([])
  const { requestNotifyError } = useGraphQl()
  const { pushSearchWhisperer: pushSearchWhisperer } = useMhub()
  const inputRef = useRef(null)
  const router = useRouter()
  const { t } = useTranslation('common')
  useDisableScroll(searchQuery && showPopUp)

  useEffect(() => {
    if (inputRef) {
      inputRef.current.value = router.query?.search ?? ''
    }
  }, [router.query?.search])

  useEffect(() => {
    fetchProducts(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    if (focusOnMount || isVisibleAsPopup) {
      inputRef.current.focus()
    }
  }, [isVisibleAsPopup])

  const fetchProducts = async (query: string) => {
    if (abortController.current) {
      abortController.current.abort()
    }
    abortController.current = new AbortController()

    if (query.length === 0) {
      setSearchProducts([])
      setSearchManufacturers([])
      setSearchCategories([])
      return
    }

    const { response, success, error, abort } = await requestNotifyError(
      (sdk: Sdk) => ({
        method: sdk.clientFullTextSearchProductsBar,
        variables: {
          filter: [{ filterType: FilterType.Search, filterIDs: [query] }],
          limit: 5
        }
      }),
      true,
      null,
      abortController.current.signal
    )

    if (success || (error && !abort)) {
      const products = response?.fulltextProductsSearchBar?.products ?? []
      const manufacturers =
        response?.fulltextProductsSearchBar?.manufacturers ?? []
      const categories = response?.fulltextProductsSearchBar?.categories ?? []
      setSearchProducts(products)
      setSearchManufacturers(manufacturers)
      setSearchCategories(categories)
      pushSearchWhisperer(
        query,
        response?.fulltextProductsSearchBar?.totalProductsCount ?? 0
      )
    }
  }

  const submitSearch = (e) => {
    e.preventDefault()
    if (!inputRef.current.value) {
      inputRef.current.focus()
      return
    }
    router.push({
      pathname: Routes.searchOur,
      query: { search: inputRef.current.value }
    })
    setShowPopUp(false)
    setShowSearchInput && setShowSearchInput(false)
  }

  const getValue = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    setShowPopUp(true)
  }

  return (
    <>
      <StyledForm onSubmit={submitSearch} key={router.asPath}>
        <StyledFlex>
          <StyledSearchInput
            ref={inputRef}
            onChange={getValue}
            onFocus={() => setShowPopUp(true)}
            placeholder={t('Toolbar.Search.inputPlaceholder')}
            data-cy="productSearchInput"
          />
          <StyledSearchButton
            type="submit"
            data-cy="headerButtonSearch"
            onClick={submitSearch}
          >
            <SearchIconDefault />
          </StyledSearchButton>
        </StyledFlex>
        {searchQuery && showPopUp && (
          <ProductSearchPopUp
            handleClose={() => setShowPopUp(false)}
            isSticky={isSticky}
            products={searchProducts}
            manufacturers={searchManufacturers}
            categories={searchCategories}
            submitSearch={submitSearch}
            setShowSearchInput={setShowSearchInput}
            searchQuery={searchQuery}
            isHeaderNoticeEnabled={isHeaderNoticeEnabled}
            showHeaderPromo={showHeaderPromo}
          />
        )}
      </StyledForm>
    </>
  )
}

export default ProductSearchPopUpWrapper

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`

const StyledSearchInput = styled.input`
  z-index: 3;
  ${(props) => props.theme.headerSearchInput && props.theme.headerSearchInput}
  height: 36px;
  flex-grow: 1;
  margin: 0;
  padding: 0 13px;
  border-top-left-radius: ${(props) => props.theme.borderRadius}px;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius}px;
  &::placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholder};
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholder};
  }
  &:focus {
    outline-style: none;
  }
`

const StyledSearchButton = styled.button`
  z-index: 3;
  height: 38px;
  width: 38px;
  background: ${(props) => props.theme.colors.headerButton};
  border: none;
  margin: 0;
  outline: black;
  align-self: end;
  border-top-right-radius: ${(props) => props.theme.borderRadius}px;
  border-bottom-right-radius: ${(props) => props.theme.borderRadius}px;
  ${(props) => props.theme.headerSearchButton && props.theme.headerSearchButton}
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.accent};
  }
`
const StyledFlex = styled(Flex)`
  z-index: 101;
  min-width: 420px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${validFor.mobile` 
    min-width: 0px;
	`};
`
