import { FC } from 'react'
import styled from 'styled-components'
import { Flex } from '@/components/atoms/Grid'
import Link from '@/components/atoms/link'
import useTranslation from 'next-translate/useTranslation'
import { validFor } from '@/theme/validFor'
import { ItemsForFullTextProduct } from '@/network/graphql.g'
import { ProductSearchBrands } from '@/components/molecules/productSearch/productSearchData'
import { v4 as uuidv4 } from 'uuid'

type BrandsListingItemsWrapperProps = {
  hideOnClick?: () => void
  manufacturers: ItemsForFullTextProduct[]
}

const BrandsListingItemsWrapper: FC<BrandsListingItemsWrapperProps> = ({
  hideOnClick,
  manufacturers
}) => {
  const { t } = useTranslation('common')
  const productSearchBrands = ProductSearchBrands()

  return (
    <BrandsListingItems onClick={hideOnClick}>
      <StyledBrandsTitle>{t('Luigi.Types.brandTitle')}</StyledBrandsTitle>
      {manufacturers.length > 0
        ? manufacturers.map((manufacturer) => (
            <StyledBrand
              href={manufacturer.url}
              key={uuidv4()}
              data-cy="brandsListingItems"
            >
              {manufacturer.title}
            </StyledBrand>
          ))
        : productSearchBrands.map((dummyBrand) => (
            <StyledBrand href={dummyBrand.url} key={dummyBrand.id}>
              {dummyBrand.title}
            </StyledBrand>
          ))}
    </BrandsListingItems>
  )
}

export default BrandsListingItemsWrapper

const BrandsListingItems = styled(Flex)`
  width: 90%;
  flex-direction: column;
  ${validFor.tablet`
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 0;
    padding-left: 20px;
  `};
  ${validFor.mobile`
    padding-top: 0; 
  `};
`

const StyledBrandsTitle = styled(Flex)`
  font-size: 18px;
  padding: 13px;
  ${validFor.tablet`
    width: 100%;
    padding: 13px 0px;
  `};
  ${validFor.mobile`
    padding-bottom:6px;;
  `};
`

const StyledBrand = styled(Link)`
  padding: 2px 13px;
  margin: 2px 0;
  font-size: 12px;
  ${validFor.tablet`
    width: 30%;
    height : 20px;
    padding: 0;
  `};
  &::before {
    content: '- ';
    position: relative;
    left: 0;
    padding-right: 5px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.luigiHoverColor};
  }
`
